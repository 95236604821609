.subopciones-container {
  position: relative !important;
}

.subopciones-container .subopciones {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  width: 100% !important;
  top: 100% !important;
  z-index: 1 !important;
}
/*SECTION*/
.page-section-xs {
  min-height: 150px !important; /* Tamaño mínimo de la sección */
}

.page-section-s {
  min-height: 200px !important; /* Tamaño mínimo de la sección */
}

.page-section-m {
  min-height: 300px !important; /* Tamaño mínimo de la sección */
}

.page-section-l {
  min-height: 400px !important; /* Tamaño mínimo de la sección */
}

.page-section-xl {
  min-height: 500px !important; /* Tamaño mínimo de la sección */
}

.page-section-xxl {
  min-height: 600px !important; /* Tamaño mínimo de la sección */
}

.page-section-xxxl {
  min-height: 759px !important; /* Tamaño mínimo de la sección */
}

/*FUENTES*/
@font-face {
  font-family: "KonnectBlack";
  src: url('../assets/fonts/KonnectBlack.otf') format('opentype');
}


@font-face {
  font-family: "KonnectBlackItalic";
  src: url('../assets/fonts/KonnectBlackItalic.otf') format('opentype');
}


@font-face {
  font-family: "KonnectBold";
  src: url('../assets/fonts/KonnectBold.otf') format('opentype');
}

@font-face {
  font-family: "KonnectBoldItalic";
  src: url('../assets/fonts/KonnectBoldItalic.otf') format('opentype');
}


@font-face {
  font-family: "KonnectExtraBold";
  src: url('../assets/fonts/KonnectExtraBold.otf') format('opentype');
}


@font-face {
  font-family: "KonnectMedium";
  src: url('../assets/fonts/KonnectMedium.otf') format('opentype');
}

@font-face {
  font-family: "KonnectExtraBoldItalic";
  src: url('../assets/fonts/KonnectExtraBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: "KonnectHairlineItalic";
  src: url('../assets/fonts/KonnectHairlineItalic.otf') format('opentype');
}

@font-face {
  font-family: "KonnectLight";
  src: url('../assets/fonts/KonnectLight.otf') format('opentype');
}

@font-face {
  font-family: "KonnectMediumItalic";
  src: url('../assets/fonts/KonnectMediumItalic.otf') format('opentype');
}

@font-face {
  font-family: "KonnectRegular";
  src: url('../assets/fonts/KonnectRegular.otf') format('opentype');
}

@font-face {
  font-family: "KonnectSemiBold";
  src: url('../assets/fonts/KonnectSemiBold.otf') format('opentype');
}

@font-face {
  font-family: "KonnectSemiBoldItalic";
  src: url('../assets/fonts/KonnectSemiBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: "KonnectThinItalic";
  src: url('../assets/fonts/KonnectThinItalic.otf') format('opentype');
}

@font-face {
  font-family: "KonnectHairline";
  src: url('../assets/fonts/KonnectHairline.otf') format('opentype');
}

@font-face {
  font-family: "KonnectItalic";
  src: url('../assets/fonts/KonnectItalic.otf') format('opentype');
}

@font-face {
  font-family: "KonnectLightItalic";
  src: url('../assets/fonts/KonnectLightItalic.otf') format('opentype');
}

@font-face {
  font-family: "KonnectThin";
  src: url('../assets/fonts/KonnectThin.otf') format('opentype');
}



.konnect-black {
  font-family: "KonnectBlack";
}

.konnect-black-italic {
  font-family: "KonnectBlackItalic";
}
.konnect-bold {
  font-family: "KonnectBold";
}

.konnect-bold-italic {
  font-family: "KonnectBoldItalic";
}
.konnect-extra-bold {
  font-family: "KonnectExtraBold";
}
.konnect-medium {
  font-family: "KonnectMedium";
}

.konnect-extra-bold-italic {
  font-family: "KonnectExtraBoldItalic";
}

.konnect-hairline-italic {
  font-family: "KonnectHairlineItalic";
}

.konnect-light {
  font-family: "KonnectLight";
}

.konnect-medium-italic {
  font-family: "KonnectMediumItalic";
}

.konnect-regular {
  font-family: "KonnectRegular";
}

.konnect-semi-bold {
  font-family: "KonnectSemiBold";
}

.konnect-semi-bold-italic {
  font-family: "KonnectSemiBoldItalic";
}

.konnect-thin-italic {
  font-family: "KonnectThinItalic";
}

.konnect-hairline {
  font-family: "KonnectHairline";
}

.konnect-italic {
  font-family: "KonnectItalic";
}

.konnect-light-italic {
  font-family: "KonnectLightItalic";
}

.konnect-thin {
  font-family: "KonnectThin";
}


/*BOTONES CLASES*/


.btn .icon-right {
  margin-left: auto;
}

.btn .text-center {
  flex-grow: 1;
  text-align: center; /* Para asegurar el centrado del texto */
}

.btn-rounded {
  border-radius: 20px !important; /* Ajusta el radio según sea necesario */
}

.btn-transparent {
    background-color: rgba(38, 38, 38, 0.5); /* Color de fondo semi-transparente */
    border: none; /* Eliminar bordes */
    color: #fff; /* Color del texto */
    padding: 10px 20px; /* Espaciado interno */
    cursor: pointer; /* Cambiar cursor al pasar sobre el botón */
    transition: background-color 0.3s; /* Transición suave del color de fondo */
    backdrop-filter: blur(5px); /* Aplicar desenfoque al fondo */
    -webkit-backdrop-filter: blur(10px); /* Para navegadores basados en WebKit */
  }

  /* Cambiar estilo al pasar el mouse sobre el botón */
  .btn-transparent:hover {
    color: #fff; /* Color del texto */
    background-color: rgba(38, 38, 38, 0.7); /* Color de fondo semi-transparente al pasar el mouse */
  }

  .btn-transparent-white {
    background-color: rgba(255, 255, 255, 0.199); /* Color de fondo semi-transparente */
    border: none; /* Eliminar bordes */
    color: #fff !important; /* Color del texto */
    padding: 10px 20px; /* Espaciado interno */
    cursor: pointer; /* Cambiar cursor al pasar sobre el botón */
    transition: background-color 0.3s; /* Transición suave del color de fondo */
    backdrop-filter: blur(2px); /* Aplicar desenfoque al fondo */
    -webkit-backdrop-filter: blur(10px); /* Para navegadores basados en WebKit */
  }

  /* Cambiar estilo al pasar el mouse sobre el botón */
  .btn-transparent-white:hover {
    color: #ffffff !important; /* Color del texto */
    background-color: rgba(236, 236, 236, 0.7); /* Color de fondo semi-transparente al pasar el mouse */
    text-decoration: none !important; 
  }


  .btn-grey {
    background-color: #262626; /* Fondo transparente */
    border-color: #262626; /* Borde transparente */
    color: #fff; /* Color del texto */
  }
  /* Estilo adicional para hover */
  .btn-grey:hover {
    color: #fff; /* Color del texto */
    background-color: #3b3b3b; /* Fondo transparente */
  }

  .btn-outline-darkblue { 
    border: 1px solid #1f3b5c !important;
    color: #1f3b5c; /* Color del texto */
  }
  /* Estilo adicional para hover */
  .btn-outline-darkblue:hover {
    color: #fff; /* Color del texto */
    background-color: #345070; /* Fondo transparente */
  }

  .btn-s {
    width: 150px;
  }

  .btn-m {
    width: 200px;
  }

  .btn-l{
    width: 250px;
  }

  .btn-xl{
    width: 300px;
  }

/*ENLACES*/
.text-decoration a {
  color: white; /* Establece el color de los enlaces a blanco */
  text-decoration: none !important; 
}

.text-undecorated a {
  text-decoration: none !important; 
}

.text-undecorated{
  text-decoration: none !important; 
}

.text-decoration a:hover {
  color: white; /* Establece el color de los enlaces a blanco */
}

/*TEXTOS*/
/* Clase para texto pequeño */
.text-xs {
  font-size: 12px !important;
}

.text-s {
  font-size: 14px !important;
}

/* Clase para texto normal */
.text-m {
  font-size: 16px !important;
}

/* Clase para texto grande */
.text-l {
  font-size: 20px !important;
}

/* Clase para texto muy grande */
.text-xl {
  font-size: 24px !important;
}

/* Clase para texto gigante */
.text-xxl {
  font-size: 32px !important;
}

.text-xxxl {
  font-size: 36px !important;
}

.text-xxxxl {
  font-size: 40px !important;
}


.text-size-1 {
  font-size: 14px !important;
}

/* Clase para texto normal */
.text-size-2 {
  font-size: 16px !important;
}

/* Clase para texto grande */
.text-size-3 {
  font-size: 20px !important;
}

/* Clase para texto muy grande */
.text-size-4 {
  font-size: 24px !important;
}

/* Clase para texto gigante */
.text-size-5 {
  font-size: 32px !important;
}

.text-size-6 {
  font-size: 36px !important;
}

.text-size-7 {
  font-size: 40px !important;
}

.text-size-8 {
  font-size: 46px !important;
}

.text-size-9 {
  font-size: 50px !important;
}

.text-size-10 {
  font-size: 56px !important;
}

/*COLORES DE TEXTO*/
.text-lightgrey{
  color: #848484 !important;
}

.text-grey{
  color: #262626 !important;
}

.text-none-decoration {
  text-decoration: none !important; 
}

.spacin-paragraphs p {
  margin-bottom: 2px; /* Espacio entre párrafos */
}

/*DIV*/
.bg-transparent {
  background-color: rgba(38, 38, 38, 0.5); /* Color de fondo semi-transparente */
  border: none; /* Eliminar bordes */
  padding: 10px 20px; /* Espaciado interno */
  cursor: pointer; /* Cambiar cursor al pasar sobre el botón */
  transition: background-color 0.3s; /* Transición suave del color de fondo */
  backdrop-filter: blur(5px); /* Aplicar desenfoque al fondo */
  -webkit-backdrop-filter: blur(10px); /* Para navegadores basados en WebKit */
}

.bg-lightgrey{
  background-color: #848484 !important;
}

.bg-grey{
  background-color: #262626 !important;
}


/* Estilos personalizados para diferentes grosores de borde */
.border-xs {
  border-width: 2px; /* Grosor del borde delgado */
}

.border-s {
  border-width: 3px; /* Grosor del borde medio */
}

.border-m {
  border-width: 4px; /* Grosor del borde grueso */
}

.border-l {
  border-width: 5px; /* Grosor del borde grueso */
}

.border-xl {
  border-width: 6px !important; /* Grosor del borde grueso */
}


/*IMAGENES*/
.img-xs {
  width: 100px !important;
  height: auto !important;
}

.img-s {
  width: 300px !important;
  height: auto !important;
}

.img-m {
  width: 500px !important;
  height: auto !important;
}

.img-l {
  width: 700px !important;
  height: auto !important; 
}

.img-xl {
  width: 900px !important;
  height: auto !important; 
}

.img-xxl {
  width: 1000px !important;
  height: auto !important; 
}

/* Clase para imágenes de ancho completo */
.img-fullwidth {
  width: 100%;
  height: auto; /* Mantener la proporción */
}

.gallery-row {
  display: flex;
}

.gallery-col {
  flex: 1;
}

.gallery-img {
  width: 100%;
  height: auto; /* Restablece la altura para que se ajuste automáticamente según la relación de aspecto */
  object-fit: cover; /* Ajustar la imagen sin distorsionar */
  aspect-ratio: 16/9; /* Establece la relación de aspecto deseada, en este caso 16:9 */
}

/*ACORDEON*/
.card.active .card-header a {
  font-weight: bold;
}


/*SLIDER*/
.slider-top-left{
  display: flex !important;
  justify-content: start !important;
  align-items: start !important;
  top: 100px !important;
  left: 25px !important;
  z-index: 1 !important;
}

.slider-top-right{
  display: flex !important;
  justify-content: end !important;
  align-items: start !important;
  top: 100px !important;
  right: 25px !important;
}

.slider-top-center{
  display: flex !important;
  justify-content: center !important;
  align-items: start !important;
  top: 100px !important;
}

.slider-bottom-left{
  display: flex !important;
  justify-content: start !important;
  align-items: end !important;
  left: 25px !important;
}

.slider-bottom-right{
  display: flex !important;
  justify-content: end !important;
  align-items: end !important;
  right: 25px !important;
}

.slider-bottom-center{
  
}

.slider-center{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  top: 0px !important;
}


.cursor-active{
  cursor: pointer !important;
}

/*FONDO*/
.opacity-25{
  opacity: 0.25;
}

.opacity-50{
  opacity: 0.5;
}


.opacity-75{
  opacity: 0.75;
}




.form-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid #ced4da;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-input {
    transition: none;
  }
}
.form-input[type=file] {
  overflow: hidden;
}
.form-input[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #8ddece;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 188, 156, 0.25);
}
.form-input::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-input::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-input:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-input::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
          margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.125rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-input::file-selector-button {
    transition: none;
  }
}
.form-input:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-input-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.125rem 0;
}
.form-input-plaintext:focus {
  outline: 0;
}
.form-input-plaintext.form-input-sm, .form-input-plaintext.form-input-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-input-sm {
  min-height: calc(1.5em + 0.75rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-input-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
          margin-inline-end: 0.5rem;
}

.form-input-lg {
  min-height: calc(1.5em + 1.25rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.75rem;
}
.form-input-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}

textarea.form-input {
  min-height: calc(1.5em + 1rem);
}
textarea.form-input-sm {
  min-height: calc(1.5em + 0.75rem);
}
textarea.form-input-lg {
  min-height: calc(1.5em + 1.25rem);
}

.form-input-color {
  width: 3rem;
  height: calc(1.5em + 1rem);
  padding: 0.375rem;
}
.form-input-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-input-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.5rem;
}
.form-input-color::-webkit-color-swatch {
  border-radius: 0.5rem;
}
.form-input-color.form-input-sm {
  height: calc(1.5em + 0.75rem);
}
.form-input-color.form-input-lg {
  height: calc(1.5em + 1.25rem);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.2rem 2.25rem 0.2rem 0.75rem !important;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5 !important;
  color: #6c757d;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 0.125rem solid #ced4da;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8ddece;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 188, 156, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.75rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8ddece;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 188, 156, 0.25);
}
.form-check-input:checked {
  background-color: #1abc9c;
  border-color: #1abc9c;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #1abc9c;
  border-color: #1abc9c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238ddece'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(26, 188, 156, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(26, 188, 156, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1abc9c;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #baebe1;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1abc9c;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #baebe1;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-input,
.form-floating > .form-input-plaintext,
.form-floating > .form-select {
  height: 2.5rem; /*ALTO DE INPUT*/
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem 0;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 0.125rem solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-input,
.form-floating > .form-input-plaintext {
  padding: 1.5rem 0;
}
.form-floating > .form-input::-moz-placeholder, .form-floating > .form-input-plaintext::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-input::placeholder,
.form-floating > .form-input-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-input:not(:-moz-placeholder-shown), .form-floating > .form-input-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
}
.form-floating > .form-input:focus, .form-floating > .form-input:not(:placeholder-shown),
.form-floating > .form-input-plaintext:focus,
.form-floating > .form-input-plaintext:not(:placeholder-shown) {
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
}
.form-floating > .form-input:-webkit-autofill,
.form-floating > .form-input-plaintext:-webkit-autofill {
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
}
.form-floating > .form-select {
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
}
.form-floating > .form-input:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.65) translateY(-0.5rem) translateX(0rem);
}
.form-floating > .form-input:focus ~ label,
.form-floating > .form-input:not(:placeholder-shown) ~ label,
.form-floating > .form-input-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.65) translateY(-0.5rem) translateX(0rem);
}
.form-floating > .form-input:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.65) translateY(-0.5rem) translateX(0rem);
}
.form-floating > .form-input-plaintext ~ label {
  border-width: 0.125rem 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-input,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-input:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0.125rem solid #ced4da;
  border-radius: 0.5rem;
}

.input-group-lg > .form-input,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.75rem;
}

.input-group-sm > .form-input,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-input,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-input,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -0.125rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-input,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.5rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-input:valid, .form-input.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-input:valid:focus, .form-input.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-input:valid, textarea.form-input.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-input-color:valid, .form-input-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-input:not(:focus):valid, .input-group > .form-input:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.5rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-input:invalid, .form-input.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-input:invalid:focus, .form-input.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-input:invalid, textarea.form-input.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-input-color:invalid, .form-input-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-input:not(:focus):invalid, .input-group > .form-input:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.form-floating input.form-input,
.form-floating textarea.form-input,
.form-floating select.form-select{
  font-size: 1.5rem;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  border-width: 1px;
}
.form-floating input.form-input:focus,
.form-floating textarea.form-input:focus,
.form-floating select.form-select:focus {
  box-shadow: none;
}
.form-floating label {
  font-size: 1rem;
  color: #6c757d;
}

.form-select option:checked {
  color: #212529; /* Color del texto para la opción seleccionada */
}



@media (max-width: 767px) {
  .carousel-inner {
    margin-top: 175px;
  }
}

@media (max-width: 767px) {
  .logo-img {
    width: 50%; /* Ajusta este valor según tus necesidades */
  }
}

@media (max-width: 767px) {
  .margin-responsive{
    margin: 10px !important;
    font-size: large;
  }
}

@media (min-width: 780px) {
  .logo-responsive{
    width: 200px;
    height: auto;
  }
}



.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.navbar:hover {
  background-color: white;
}

.navbar-brand {
  font-weight: bold;
  position: absolute;
  left: 20px;
}

.nav-link {
  color: #333;
  position: relative;
}

.nav-link:hover {
  color: #dc3545;
}

.nav-link:hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 100%;
  height: 2px;
  background-color: #dc3545;
}




.dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.subnavbar {
  background-color: rgba(255, 255, 255, 0.8);
}


.cursor-active-option {
  position: relative;
  cursor: pointer;
}

.active-slide-option::after {
  content: '';
  position: absolute;
  bottom: -14px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: red;
}

.cursor-inactive-option {
  color: gray;
}

.text-menu {
  color: #343a40 !important;
}
.text-menu:hover {
  color: red !important;
}

.image-container {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.image-title {
  position: absolute;
  top: 15px;
  left: 12px;
  color: white;
 padding: 4px 8px;
  border-radius: 4px;
  font-size: 1.9rem;
  z-index: 1;
}


.gallery1-img {
  width: 100%;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.image-container:hover .gallery1-img {
  transform: scale(1.1); /* Aplica el efecto de zoom */
}


.image-title2 {
  position: absolute;
  bottom: 15px;
  left: 12px;
  color: white;
 padding: 4px 8px;
  border-radius: 4px;
  font-size: 1.9rem;
  z-index: 1;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.image-container:hover .gallery-zoom {
  transition: transform 0.5s ease;
  transform: scale(1.1); /* Aplica el efecto de zoom */
}

.title-border-left {
  position: relative;
  display: inline-block;
  font-weight: bold;
  padding-bottom: 8px;
}

.title-border-left::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 20%;
  height: 4px;
  background-color: red;
}


.logo-mobile {
  min-width: 200px; /* Ajusta el tamaño según sea necesario */
  height: auto;
}

/* Ajusta el espaciado del botón de menú */
.navbar-toggler {
  padding: 0.25rem 0.5rem; /* Reduce el padding del botón de menú para ahorrar espacio */
  
}

/* Opcional: Oculta el contorno del botón al hacer clic */
.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}



.social-media-float {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 1000;
}

.social-media-float .icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 60px; /* Estilo por defecto */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: large;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

/* Para pantallas pequeñas, como móviles (ancho menor a 768px) */
@media (max-width: 768px) {
  .social-media-float .icon {
    background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 75px; /* Estilo por defecto */
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: large;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}
}

.social-media-float .icon-wt {
  background-color: rgba(30, 190, 165, 0.8);
  border-radius: 50%;
  width: 60px; /* Estilo por defecto */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: large;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

/* Para pantallas pequeñas, como móviles (ancho menor a 768px) */
@media (max-width: 768px) {
  .social-media-float .icon-wt {
    background-color: rgba(30, 190, 165, 0.8);
  border-radius: 50%;
  width: 75px; /* Estilo por defecto */
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: large;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}
}

.social-media-float .icon:hover {
  background-color: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.social-media-float .icon-wt:hover {
  background-color: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.social-media-float .facebook { color: #ffffff; }
.social-media-float .facebook:hover { color: #000; }
.social-media-float .whatsapp { color: #ffffff; }
.social-media-float .whatsapp:hover { color: #4fce5d; }
.social-media-float .instagram { color: #ffffff; }
.social-media-float .instagram:hover { color: #000; }
.social-media-float .youtube { color: #ffffff; }
.social-media-float .youtube:hover { color: #000; }


.option-footer { color: #fff; }
.option-footer:hover { color: #cc0000; }


.image-container-marca {
  position: relative;
  height: 100%; /* Asegura que ocupe el 100% de la altura del contenedor */
}

.image-container-marca img {
  width: 100%;
  height: 100%; /* Asegura que ocupe todo el contenedor */
  object-fit: cover; /* Mantiene la proporción de la imagen y la recorta si es necesario */
  display: block;
}

/* Posición base y estilos comunes */
.overlay-text {
  position: absolute;
  color: white;
  max-width: 80%; /* Evita que el texto se salga del contenedor */
  transform: translate(-50%, -50%);
}

/* Posicionamiento específico */

/* Izquierda Arriba */
.overlay-text-left-top {
  top: 10%; /* Separación desde el borde superior */
  left: 10%;
  transform: translate(0, 0);
}

/* Izquierda Centrado */
.overlay-text-left-center {
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
}

/* Izquierda Abajo */
.overlay-text-left-bottom {
  bottom: 10%; /* Separación desde el borde inferior */
  left: 10%;
  transform: translate(0, 0);
}

/* Centro Centrado */
.overlay-text-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Derecha Arriba */
.overlay-text-right-top {
  top: 10%;
  right: 10%; /* Separación desde el borde derecho */
  transform: translate(0, 0);
}

/* Derecha Centrado */
.overlay-text-right-center {
  top: 50%;
  right: 10%;
  transform: translate(0, -50%);
}

/* Derecha Abajo */
.overlay-text-right-bottom {
  bottom: 10%; /* Separación desde el borde inferior */
  right: 10%;
  transform: translate(0, 0);
}


body, html {
  max-width: 100% !important;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .banner-first {
    margin-top: 120px !important;
    position: relative;
  }
}


@font-face {
  font-family: "Poppins-Black";
  src: url('../assets/fonts/Poppins-Black.ttf') format('truetype');
}

.Poppins-Black {
  font-family: "Poppins-Black";
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: url('../assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
}

.Poppins-BlackItalic {
  font-family: "Poppins-BlackItalic";
}

@font-face {
  font-family: "Poppins-Bold";
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

.Poppins-Bold {
  font-family: "Poppins-Bold";
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

.Poppins-BoldItalic {
  font-family: "Poppins-BoldItalic";
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

.Poppins-ExtraBold {
  font-family: "Poppins-ExtraBold";
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url('../assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

.Poppins-ExtraBoldItalic {
  font-family: "Poppins-ExtraBoldItalic";
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

.Poppins-ExtraLight {
  font-family: "Poppins-ExtraLight";
}


@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}

.Poppins-ExtraLightItalic {
  font-family: "Poppins-ExtraLightItalic";
}


@font-face {
  font-family: "Poppins-Italic";
  src: url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
}

.Poppins-Italic {
  font-family: "Poppins-Italic";
}


@font-face {
  font-family: "Poppins-Light";
  src: url('../assets/fonts/Poppins-Light.ttf') format('truetype');
}

.Poppins-Light {
  font-family: "Poppins-Light";
}


@font-face {
  font-family: "Poppins-LightItalic";
  src: url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
}

.Poppins-LightItalic {
  font-family: "Poppins-LightItalic";
}


@font-face {
  font-family: "Poppins-Medium";
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

.Poppins-Medium {
  font-family: "Poppins-Medium";
}


@font-face {
  font-family: "Poppins-MediumItalic";
  src: url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
}

.Poppins-MediumItalic {
  font-family: "Poppins-MediumItalic";
}


@font-face {
  font-family: "Poppins-Regular";
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

.Poppins-Regular {
  font-family: "Poppins-Regular";
}


@font-face {
  font-family: "Poppins-SemiBold";
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

.Poppins-SemiBold {
  font-family: "Poppins-SemiBold";
}


@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}

.Poppins-SemiBoldItalic{
  font-family: "Poppins-SemiBoldItalic";
}


@font-face {
  font-family: "Poppins-Thin";
  src: url('../assets/fonts/Poppins-Thin.ttf') format('truetype');
}

.Poppins-Thin {
  font-family: "Poppins-Thin";
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: url('../assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
}

.Poppins-ThinItalic {
  font-family: "Poppins-ThinItalic";
}

